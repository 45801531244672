/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 26/07/2024 - 10:35:08
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 26/07/2024
    * - Author          : 
    * - Modification    : 
**/


export const SOLAR_BATTERY_CONFIGS = {
    'SOLAR_TABS' : ['project-detail','project-files','energy-profile' , 'solutions' ,'contracts','sold-project','post-sale'],
    'BATTERY_TABS' : ['project-detail','project-files','energy-profile', 'solutions' ,'contracts','sold-project','post-sale'],
    'EVSE_TABS' :['project-detail','project-files','customer-questionnaire', 'quotes']
}


export const COMMON_MODULE_CONFIGS = {
        PANELBOX_VALUES : [
            {
              name: "50 Amp",
              value: "50 Amp",
            },
            {
              name: "100 Amp",
              value: "100 Amp",
            },
            {
              name: "125 Amp",
              value: "125 Amp",
            },
            {
              name: "150 Amp",
              value: "150 Amp",
            },
            {
              name: "200 Amp",
              value: "200 Amp",
            },
            {
              name: "400 Amp",
              value: "400 Amp",
            },
          ],
        PROJECT_TYPE_MAPPING:{
            "Solar": "Solar",
            "Solar+Battery": "Solar + Battery",
            "Battery": "Battery",
            "SolarTPO": "Solar TPO",
            "Solar-OfflineBids": "Solar - Offline Bids",
            "Solar-Lessthan3Bids": "Solar - Less than 3 Bids",
            "CommunitySolar": "Community Solar",
            "OUC-Solar": "OUC - Solar",
            "OUC-Solar+Battery": "OUC - Solar + Battery",
            "OUC-Battery": "OUC - Battery",
            "HPWH": "HPWH",
            "evCharger": "evCharger"
        },

        PROJECT_TYPE:{
          "Solar": "Solar",
          "Solar+Battery": "Solar + Battery",
          "Battery": "Battery",
          "SolarTPO": "Solar TPO",
          "Solar-OfflineBids": "Solar - Offline Bids",
          "Solar-Lessthan3Bids": "Solar - Less than 3 Bids",
          "CommunitySolar": "Community Solar",
          "OUC-Solar": "OUC - Solar",
          "OUC-Solar+Battery": "OUC - Solar + Battery",
          "OUC-Battery": "OUC - Battery",
          "HPWH": "HPWH",
          "evCharger": "EV Charger"
      },

        OVERVIEW_NOTES_TABS:[
          {
            label:'Notes for Sales Team',
            description:'Use this tab to share updates,strategies, and key customerinsights with the Sales team.',
            type:'Sales'
          },
          {
            label:'Notes for Design Team',
            description:'Post notes here for the Design team. Include project specifications, design feedback, and collaboration requests from Sales or Customer Success.',
            type:'Design',
          },
          {
            label:'Notes for Customer Success & Installers',
            description:'This section is for coordinating with the Customer Success team and installers. Share installation details that the Customer Success team may need to pass to the installer to ensure seamless service delivery.',
            type:'Installer'
          }]
}

export const PROJECT_LISTVIEW_CONFIGS = {
    PRODUCT_NAME_MAPPING:{
        'solar+battery' : 'Solar + Battery',
        'Solar': 'Solar',
        'Battery': 'Battery',
        'evCharger': 'EV Charger',
        'HPWH': 'Water Heater',
        'CommunitySolar' : 'Community Solar',
        'Solar+Battery' : 'Solar + Battery'

    }
}

export const PROJECT_FILESLIST_CONFIGS ={
   FILE_TYPE_MAPPING : {
    utility: "Utility Bill",
    electricitybill: "Utility Bill",
    panel_box_images: "Panel Box Photo",
    panelboxphoto: "Panel Box Photo",
    panel_box_photo: "Panel Box Photo",
    ev_charger_location: "EV Charger Photos",
    wh_image: "Water Heater Image",
    design: "Design File",
    designpreview: "Design Preview",
    designerpanelgroup: "Design File",
    revision: "Design File",
    feedback: "Design File",
    quote: "Competitor Quote",
    "site evaluations": "Site Evaluation",
    siteevaluation: "Site Evaluation",
    other: "Other",
    electrum_drive: "Other",
    org_drive: "Other",
    installer_drive: "Other",
    mockup2d: "Site Images",
    mockup3d: "Site Images",
    irradiance: "Site Images",
    streetview: "Site Images",
    angle1: "Site Images",
    angle2: "Site Images",
    contract: "Contract",
    "contract cocuments": "Contract",
    contractdocuments: "Contract",
    usagedata: "Usage Data",
    shading_report: "Shading Report",
    SGIP: "SGIP",
    contract_addendum: "Contract Addendum",
    installation_photos: "Installation Photos",
    incentives_documents: "Incentives Documents",
  }
}

export const ENERGY_PROFILE_CONFIGS = {
  adjustment_reasons: [
    {
      label: "No planned usage adjustment",
      name: "No planned usage adjustment",
    },
    { label: "Electric Vehicle(s)", name: "Electric Vehicle(s)" },
    { label: "Home addition", name: "Home addition" },
    { label: "Smaller/larger family", name: "Smaller/larger family" },
    { label: "Major appliance(s)", name: "Major appliance(s)" },
    { label: "Energy efficiency upgrades", name: "Energy efficiency upgrades" },
    { label: "Other", name: "Other" },
  ],
  intervals: [
    {
      label: "Hourly",
      name: "hourly",
    },
    {
      label: "Monthly",
      name: "monthly",
    },
    {
      label: "Annual",
      name: "annual",
    },
  ],
};