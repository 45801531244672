import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const DropdownMulti = (props: any) => {
  // console.log(props);
  const [personName, setPersonName] = React.useState(['All']);

  React.useEffect(() => {
    if(props.render){
      setPersonName(['All'])
    }
  }, [props.render])

  const handleChange = (event: any) => {
    var {
      target: { value },
    } = event;
       if(value[value.length-1] === 'All'){
           value = value.length === props.data.length +1 ? [] : props.data
           setPersonName(typeof value === 'string' ? value.split(',') : value,
        )
       }else{
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          )  
       }

       props.handleSelection(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 180 }}>
        <Select
          multiple
          value={personName}
          displayEmpty
          onChange={handleChange}
          renderValue={(selected) => (
              selected.length === props?.data?.length ? 'All' : selected.length >1? 'Multiple Values' : selected.join(', ')) }
        >
          <MenuItem key="index" value="All">
              <Checkbox checked={personName.indexOf("All") > -1 || personName.length === props.data.length} />
              <ListItemText primary="All" />
          </MenuItem>  
          {props?.data?.map((name: any, index: number) => (
            <MenuItem key={index} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1 || personName.indexOf("All") > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropdownMulti
