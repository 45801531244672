import * as React from 'react';
import { Select, MenuItem, FormControl, InputLabel, styled, ListItemText, Checkbox } from "@mui/material";


const CenteredInputLabel = styled(InputLabel)({
    textAlign: 'center',
    transform: 'translate(14px, 14px) scale(1)',
    fontFamily: 'ActualR',
    fontWeight: 400,
    fontSize: '14px',
    color: '#333333',
    '&.Mui-focused': {
        transform: 'translate(14px, -8px) scale(0.85)',
        color: "#333333",
    },
    '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.85)',
    },
    '&[data-shrink="false"] + .MuiInputBase-formControl .MuiSelect-select': {
        // borderTop: '1px solid #aaa',
        paddingTop: '16px'
    }
});


const CustomSelect = styled(Select)(() => ({
  width: 300,
  height: "48px",
  display: "flex",
  alignItems: "center",
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#aaa",
    },
    "&:hover fieldset": {
      borderColor: "#aaa",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#aaa",
    },
  },
}));




const DropdownMulti = (props: any) => {
  const [personName, setPersonName] = React.useState([]);
  const [options, setOptions] = React.useState([]);



  React.useEffect(()=>{
    if(options.length <= 0){
      setOptions(props?.data);
    }
  },[props?.data])


  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChange = (event: any) => {
    if(event.key === 'Enter'){
      event.preventDefault();
    }else{
      var {
        target: { value },
      } = event;
         if(value[value.length-1] === 'All'){
             value = value.length === props.data.length +1 ? [] : props.data
             setPersonName(typeof value === 'string' ? value.split(',') : value,
          )
         }else{
          setPersonName(
              // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value,
            )  
         }
  
         props.handleSelection(value);
    }
  };

  const filterOptions = (value:any,options:any,initialOptions:any) => {
    let filteredArray = options.filter((item:any)=>item.toLowerCase().includes(value.toLowerCase()));
    if(filteredArray.length>0){
      setOptions(filteredArray);
    }else{
      setOptions(initialOptions);
    }
  }
  
  const handleClose = () => {
    setOptions(props?.data);
  }

  


  return (
    <FormControl variant="outlined">
      <CenteredInputLabel
        id="demo-simple-select-helper-label"
        htmlFor="select-multiple"
      >
        {props?.label}
      </CenteredInputLabel>
      <CustomSelect
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        multiple
        disableUnderline
        value={personName}
        displayEmpty
        size="small"
        onChange={handleChange}
        onClose={handleClose}
        IconComponent={() => <i className="icon-h-down-arrow icon_downarrow" />}
        renderValue={(selected: any) =>
          selected.length > 0 && selected.length === options?.length
            ? "All"
            : selected.length > 1
            ? "Multiple Values"
            : selected.join(", ")
        }
        {...props}
      >
        <MenuItem
          value={""}
          onClickCapture={stopImmediatePropagation}
          onKeyDown={(e) => e.stopPropagation()}
          style={{background:'transparent'}}
        >
          <input
            className="search_box"
            type="text"
            placeholder="Search"
            onChange={(e: any) =>
              filterOptions(e.target.value, props?.data, props?.partner)
            }
          />
        </MenuItem>
        <MenuItem key="index" value="All">
          <Checkbox
            checked={
              personName.indexOf("All") > -1 ||
              personName.length === props.data.length
            }
          />
          <ListItemText primary="All" />
        </MenuItem>
        {options?.map((name: any, index: number) => (
          <MenuItem key={index} value={name} title={name}>
            <Checkbox
              checked={
                personName.indexOf(name) > -1 || personName.indexOf("All") > -1
              }
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
}

export default DropdownMulti
