/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 02/08/2024 - 14:45:51
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 02/08/2024
    * - Author          : 
    * - Modification    : 
**/
import React, {useEffect} from 'react';
import { DataGrid, GridRowHeightParams } from "@mui/x-data-grid";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  // GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  gridPaginationRowRangeSelector
} from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';
import { FormControl, MenuItem, Select } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { LANG } from '../../config';

var gridParams = {
  type : 'client',
  pageSize : 10,
  total : 0
}

const CustomPagination = ({ paginationProps, ...otherProps }) => {
    const apiRef = useGridApiContext();
    const pageNumberFromSelector = useGridSelector(apiRef, gridPageSelector);
    let page = paginationProps.pgnum ? paginationProps.pgnum - 1 : pageNumberFromSelector;
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const paginationRowRange = useGridSelector(apiRef, gridPaginationRowRangeSelector);

    useEffect(() => {
      apiRef.current.setPage(paginationProps.pgnum - 1)
    },[paginationProps.pgnum])
    return (
      <div style={{borderTop:'1px solid #eee'}}>
          {
          gridParams.type === 'server' ?
          <div className={`${paginationProps.rowsPerPageSelection ? "d-flex align-items-baseline justify-content-between":"d-flex align-items-baseline justify-content-end"}`} style={{padding:'8px 16px'}}>
            {paginationProps.rowsPerPageSelection && 
          <div id="custom-pagination" style={{display:'flex', justifyContent:'center', alignItems:'center', gap:'16px'}}> 
            <p className='pagination-text-style'>View</p>

            {/* <FormControl sx={{ m: 1 }} variant="outlined" className='selectRows'> */}
              <Select
                sx={{ height:'40px', width:'69px', display:'flex', borderRadius:'4px', border: '1px solid rgba(102, 102, 102, 0.50)', justifyContent:'flex-start', alignItems:'center', gap:'8px', flex:'1 0 0' }}
                id="pagination-customized-select"
                value={paginationProps.rowsPerPage}
                IconComponent={() => <i className="icon-angle-down" style={{color:'#5029DD', right:'8px',  position:'absolute', fontSize:'24px', cursor:'pointer', width:'24px', textAlign:'right'}}></i>}
                onChange={(e) => {
                  paginationProps.handleRowsPerPageChange(parseInt(e.target.value));
                }}
                // input={<BootstrapInput />}
              >
                {paginationProps.rowsPerPageSelection?.map((item: any, index: any) => {
                      return <MenuItem key={index} value={item}>{item}</MenuItem>
                })}
              </Select>
            {/* </FormControl> */}
            <p className='pagination-text-style'>Per page</p>
          </div>}
          <div className='d-flex align-items-baseline'>
          <div className='pagination-text-style'>Showing {(gridParams.pageSize*page) + 1} to {(gridParams.total - gridParams.pageSize*(page+1) >=0 ? gridParams.pageSize*(page+1) : gridParams.total)} of {gridParams.total} entries</div>
          <Pagination
            className='custom-pagination-item'
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event: any, value: any) => apiRef.current.setPage(value - 1)}
          />
      </div>
          </div>
           :
          <div className="d-flex align-items-baseline justify-content-end" style={{padding:'16px'}}>
          <div className='pagination-text-style'>Showing {paginationRowRange?.firstRowIndex+1} to {paginationRowRange?.lastRowIndex+1} of {apiRef.current.getAllRowIds().length} entries</div>
          <Pagination
          className='custom-pagination-item'
          color="primary"
          count={pageCount}
          page={page + 1}
          onChange={(event: any, value: any) => apiRef.current.setPage(value - 1)}
        /></div>
        }
      </div>
    );
  }

  // function Pagination({
  //   page,
  //   onPageChange,
  //   className,
  // }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  //   const apiRef = useGridApiContext();
  //   const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
  //   return (
  //     <MuiPagination
  //       color="primary"
  //       className={className}
  //       count={pageCount}
  //       page={page + 1}
  //       onChange={(event, newPage) => {
  //         onPageChange(event as any, newPage - 1);
  //       }}
  //     />
  //   );
  // }
  
  // function CustomPagination(props: any) {
  //   return <GridPagination ActionsComponent={Pagination} {...props} />;
  // }
 
 function CustomToolbar() {
  return (
    <GridToolbarContainer className="grid-toolbar-cont">
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector /> */}
      <GridToolbarExport  printOptions={{
    hideFooter: true,
    hideToolbar: true,
  }} />
    <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
} 

function CustomNoRowsOverlay() {
  return (
    <div style={{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      height:'auto',
      width:'100%',
      position:'relative',
      top:'40px',
      bottom:'0px'
    }}>{LANG.NO_DATA_AVAILABLE}</div>
  );
}

const Grid: React.FC<any> = (props: any) => {
  gridParams = {
  total : props.rowCount,
  type : props.paginationMode === 'server' ? 'server' : 'client',
  pageSize : props.pageSize
}
const paginationProps : any = {
  pgnum : props?.pagenumber,
  rowsPerPage : props?.pageSize,
  rowsPerPageSelection : props?.rowsPerPageSelection,
  handleRowsPerPageChange : props?.handleRowsPerPageChange,
}
const defaultValue = () => {
  return props.getClassName ? props.getClassName : '';
}
        return (
            props.paginationMode === 'server' ?
            <DataGrid columns={props.columns}
            rows={props.rows}
            pageSize={props.pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            pagination 
            autoHeight ={true}
            paginationMode="server"
            rowCount={props.rowCount}
            slots={{
              LoadingOverlay: LinearProgress,
            }}
            loading = {props.loading}
            onPageChange = {(newPage: any) => props.onPageChange(newPage)}
            initialState = {props.initialState ? props.initialState : {}}
            getRowClassName = {defaultValue}
            localeText={{
              toolbarDensity: 'Size',
              toolbarDensityLabel: 'Size',
              toolbarDensityCompact: 'Small',
              toolbarDensityStandard: 'Medium',
              toolbarDensityComfortable: 'Large',
            }}
            components={{
              Footer: (props) => <CustomPagination {...props} paginationProps={paginationProps}/>,
              Toolbar: props.Toolbar ? CustomToolbar : '',
            NoRowsOverlay: CustomNoRowsOverlay,
            LoadingOverlay: LinearProgress,
         }}
           ComponentsProps={{ toolbar: { printOptions: { disableToolbarButton: true },
           csvOptions: { allColumns: true } 
           }
           }}
           getRowHeight={({densityFactor }: GridRowHeightParams) => {
            if(props.densityNum && props.densityNum === 'auto'){
              return 'auto'
            }else{
              return (props.densityNum? props.densityNum : 60) * densityFactor;
            }
          }}
          {...props}
           disableColumnMenu
           /> 
           : 
           <DataGrid columns={props.columns}
           rows={props.rows}
           pageSize={props.pageSize}
           rowsPerPageOptions={[10, 20, 30]}
           pagination 
           autoHeight ={true}
           slots={{
            LoadingOverlay: LinearProgress,
          }}
          loading = {props.loading} 
           initialState = {props.initialState ? props.initialState : {}}
           getRowClassName = {defaultValue}
           localeText={{
             toolbarDensity: 'Size',
             toolbarDensityLabel: 'Size',
             toolbarDensityCompact: 'Small',
             toolbarDensityStandard: 'Medium',
             toolbarDensityComfortable: 'Large',
           }}
           components={{
             Footer: props.footer? props.footer : CustomPagination,
             Toolbar: props.Toolbar ? CustomToolbar : '',
             NoRowsOverlay: CustomNoRowsOverlay,
             LoadingOverlay: LinearProgress,
          }}
          ComponentsProps={{ toolbar: { printOptions: { disableToolbarButton: true },
          csvOptions: { allColumns: true } 
          }
          }}
          getRowHeight={({densityFactor }: GridRowHeightParams) => {
            if(props.densityNum && props.densityNum === 'auto'){
              return 'auto'
            }else{
              return (props.densityNum? props.densityNum : 60) * densityFactor;
            }
          }}    
          {...props}
          disableColumnMenu
          /> 
        );
}

export default Grid;

