/**
    * @description      : 
    * @author           : 
    * @group            : 
    * @created          : 26/07/2024 - 12:09:00
    * 
    * MODIFICATION LOG
    * - Version         : 1.0.0
    * - Date            : 26/07/2024
    * - Author          : 
    * - Modification    : 
**/
import React, {useState,useEffect,lazy,Suspense} from 'react'
import { useParams } from "react-router-dom";
import './Financier.style.scss';
import { Box } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch, useSelector } from 'react-redux';
import {isEmpty} from "lodash";
import { DASHBOARD } from '../../../../../navigation/CONSTANTS';
import {SOLAR_BATTERY_CONFIGS} from '../../Configuration/config';
import { getprojectDetails,getProjectStatus, setProjectDetails, setUpdateProjectDetailsFlag } from "../../../../../store/projectList/projectListSlice";
import { useNavigate } from 'react-router-dom';
import { setMarkAsComplete } from '../../../../../store/systemDesign/systemDesignSlice';
import { isActionAllowed } from '../../../../../utils/helper';
import { Skeleton } from '@mui/material';
const AccountDetail = lazy(() => import("../AccountDetail"));
const ProjectOverview = lazy(()=>import("../SingleProjectDetail/index"));
const ProjectFiles = lazy(()=> import("../ProjectFile/FilesList"));
const Consumption = lazy(() => import("../../SolarBatteryModule/EnergyProfile"));
const Solutions = lazy(() => import("../../../projectsModule/SolarBatteryModule/Solutions"));
const Contracts = lazy(()=> import("../../../Projects/component/Contracts"));
const SoldProject = lazy(()=> import("../../../Projects/component/SoldProjects/index"));
// const PostSale = lazy(() => import('../../../Projects/component/PostSale'));

const EvseQuestionnaire = lazy(() => import('../../Evse/Questionnaire/index'))
const EvseQuotes = lazy(()=>import('../../Evse/quotes/index'))
/**TODO
 * Tabs Configure for solar & battery
 * Import tab content module  -> Overview / Project Files / Energy Profile
 */

function Financier() {
  const systemDesignData = useSelector((store) => store.systemDesign);
  const projectDetail = useSelector((store) => store.projectList);

  const navigate = useNavigate();
  const dispatch = useDispatch();
 const [value, setValue] = useState(0);
 const { id, type } = useParams();



 const financierType = (projectDetail?.projectDetails?.partner_project_type === 'Battery')?SOLAR_BATTERY_CONFIGS.BATTERY_TABS:(projectDetail?.projectDetails?.partner_project_type === 'evCharger')?SOLAR_BATTERY_CONFIGS.EVSE_TABS:SOLAR_BATTERY_CONFIGS.SOLAR_TABS 

 useEffect(()=>{
   if(projectDetail.projectStage !== null)
   {
    handleChange('',projectDetail.projectStage)
    
   }
 },[projectDetail.projectStage])

 useEffect(() => {   
  dispatch(getprojectDetails(id));
  dispatch(getProjectStatus(id));
  return () => {
    dispatch(setProjectDetails({}));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 },[id])
 useEffect(() => {
  if(!isEmpty(projectDetail?.projectDetails)){
    if(financierType.indexOf(type) >= 0){
      setValue(financierType.indexOf(type));
     }else{
       navigate(DASHBOARD.PROJECT_LIST);
     }
  }
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [financierType,projectDetail?.projectDetails]);

 // This useEffect is used to auto route to solutions page when the user clicks on Mark as complete in System Design page
 useEffect(()=>{
  if(systemDesignData.markAsComplete===2){
    dispatch(setMarkAsComplete(0))
    setValue(2)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 },[systemDesignData.markAsComplete]);



  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/dashboard/projects/${financierType[newValue]}/${id}`)
      dispatch(setUpdateProjectDetailsFlag(false));
  };

  const evStatus =  ["Uploaded Photos","Assessment","Project Submitted","Questionnaire Completed"]

  return (
    <div className="main_details_section">
      <>
        <div className="headerImpersonate">
          <Suspense fallback={<></>}>
            <AccountDetail data={projectDetail?.projectDetails}></AccountDetail>
          </Suspense>
        </div>
        {!projectDetail?.projectDetailsLoader ? (
          <div className="financier_component">
            {projectDetail?.projectDetails?.partner_project_type ===
            "evCharger" ? (
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    className="tabs_root"
                    aria-label="basic tabs example"
                  >
                    <Tab className="tab" label="Overview" />
                    <Tab className="tab" label="Project Files" />
                    <Tab className="tab" label="Customer Questionnaire" />
                    {!evStatus.includes(
                      projectDetail?.projectDetails?.project_status
                    ) && <Tab className="tab" label="Quotes" />}
                  </Tabs>
                </Box>
                <div className="tab_panel">
                  <TabPanel value={value} index={0}>
                    <Suspense fallback={<></>}>
                      <ProjectOverview></ProjectOverview>
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Suspense fallback={<></>}>
                      <ProjectFiles />
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Suspense fallback={<></>}>
                      <EvseQuestionnaire />
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Suspense fallback={<></>}>
                      <EvseQuotes />
                    </Suspense>
                  </TabPanel>
                </div>
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    className="tabs_root"
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab className="tab" label="Overview" />
                    <Tab className="tab" label="Project Files" />
                    <Tab className="tab" label="Energy Profile" />
                    <Tab
                      className="tab"
                      label="Solutions"
                      disabled={true}
                    />
                    {/* disabled={!(projectDetail?.projectStatus?.designStatus || (projectDetail?.projectStatus?.consumptionStatus && projectDetail?.projectDetails?.partner_project_type === 'Battery'))} */}

                    <Tab
                      className="tab"
                      label="Contracts"
                      disabled={true}
                    />
                    <Tab
                      className="tab"
                      label="Sold Project Details"
                      disabled={true}
                    />
                    {/* <Tab
                      className="tab"
                      label="Post Sale"
                      // disabled={!projectDetail?.projectStatus?.postSaleStatus}
                    /> */}
                  </Tabs>
                </Box>
                <div className="tab_panel">
                  <TabPanel value={value} index={0}>
                    <Suspense fallback={<></>}>
                      <ProjectOverview></ProjectOverview>
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Suspense fallback={<></>}>
                      <ProjectFiles />
                    </Suspense>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Suspense fallback={<></>}>
                      <Consumption></Consumption>
                    </Suspense>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={financierType.indexOf("solutions")}
                  >
                    {/* Solutions page here */}
                    <Suspense fallback={<></>}>
                      {/* {isActionAllowed(["hubble-view-solution-list"]) ? (
                        <div className='p-3' style={{background:'#fff', borderRadius:'4px'}}>
                          <Solutions></Solutions>
                        </div>
                      ) : ( */}
                        <p
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          You are not authorized to view this page.
                        </p>
                      {/* )} */}
                    </Suspense>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={financierType.indexOf("contracts")}
                  >
                    {/* Contracts page here */}
                    <Suspense fallback={<></>}>
                      {/* {isActionAllowed(["hubble-view-contract-page"]) ? (
                        <Contracts></Contracts>
                      ) : ( */}
                        <p
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          You are not authorized to view this page.
                        </p>
                      {/* )} */}
                    </Suspense>
                  </TabPanel>
                  {/* </TabPanel> */}
                  <TabPanel
                    value={value}
                    index={financierType.indexOf("sold-project")}
                  >
                    {/* Contracts page here */}
                    <Suspense fallback={<></>}>
                      {/* {isActionAllowed([
                        "hubble-view-sold-project-detail-page",
                      ]) ? (
                        <SoldProject></SoldProject>
                      ) : ( */}
                        <p
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          You are not authorized to view this page.
                        </p>
                      {/* )} */}
                    </Suspense>
                  </TabPanel>

                  <TabPanel
                    value={value}
                    index={financierType.indexOf("post-sale")}
                  >
                    {/* <Suspense fallback={<></>}>
                      {isActionAllowed([]) ? (
                        <PostSale></PostSale>
                      ) : (
                        <p
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          You are not authorized to view this page.
                        </p>
                      )}
                    </Suspense> */}
                  </TabPanel>
                </div>
              </Box>
            )}
          </div>
        ) : (
          <div className="financier_component">
            <div style={{ display: "flex", padding: "0px 16px", gap: "16px" }}>
              <Skeleton animation="wave" height={48} width={224} />
              <Skeleton animation="wave" height={48} width={224} />
              <Skeleton animation="wave" height={48} width={224} />
              <Skeleton animation="wave" height={48} width={224} />
              <Skeleton animation="wave" height={48} width={224} />
            </div>
            <Skeleton
              variant="rounded"
              animation="wave"
              height={720}
              width={"100%"}
            />
          </div>
        )}
      </>
    </div>
  );
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

export default Financier