import 'moment-timezone';
import { PERMISSIONS } from "../../config";
import { SSO_ORIGIN } from "../../store/CONSTANTS";
import store from '../../store';
import moment from 'moment';
var timer: any;


export const handlePatternValidation = (pattern: any, value: any) => {
    return pattern.test(value);
};

export const isActionAllowed = (actionUrl = []) => {
  const state = store.getState();
  if(actionUrl.length === 0) return true;
  // eslint-disable-next-line array-callback-return
  const count = state.config.userPermissions.filter((permission: any) => {
    if(actionUrl.indexOf(permission?.name) >= 0) {
      return true;
    }
  })
  return count.length > 0
};


export const isActionAllowedAuth = (actionUrl:any = []) => {
  if(actionUrl.length === 0) return true;
  const count = actionUrl.filter((url: any) => {
    return PERMISSIONS.indexOf(url) >= 0 ? true : false
  }
  )
  return count.length > 0
}

export const isModuleAllowed = (actionUrl: any = '') => {
  const state = store.getState();
  if(actionUrl[0] === '') return true;
  return (state.config.userModules.indexOf(actionUrl[0]) >= 0) ? true : false;
};

export const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

export const establishCommunicationToSSO = (message: any) => {
  let frame = document.getElementById('ssoFrame');
    if (isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage(message,SSO_ORIGIN);
    }
} 

export const capitalize = (str: any) =>{
  if(str === '' || str === null|| str === undefined || typeof str !== 'string') return ;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
} 

export  const swapKeysAndValues = (obj: any) => {
  const swapped = Object.entries(obj).map(
    ([key, value]) => [value, key]
  );
  return Object.fromEntries(swapped);
}

export const  debounce = (fn: any, delay: any) =>  {
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export const getDate = (date: any, type: any, format: any) => {
  if (date === null) return;
  const newDate = new Date(date);
  return type === "date"
    ? moment.utc(newDate).tz("America/Los_Angeles").format("MM/DD/yyyy")
    : moment
      .utc(newDate, "hh:mm A")
      .tz("America/Los_Angeles")
      .format("MM/DD/yyyy - hh:mm A");
};

 export const isEmptyObject = (obj: any) => {
  if(obj === null || obj === undefined) return true;
  return Object.keys(obj).length === 0;
 }

 export const extractkeysFromObject = (obj: any) => {
  if(obj === null || obj === undefined) return [];


 }

 export const getProjectType = () => {
  const state = store.getState();
  console.log('projectType', state)
  return state.projectList.projectDetails.partner_project_type 
 }

 export const getProjectTypeValid = (type:any) => {
  const state = store.getState();
  return state.projectList.projectDetails.partner_project_type === type; 
 }

 export const formatNumberWithCommas = (num: any, min = 2, max = 2) => {
  if(num === null || num === undefined) return '-';
  return num.toLocaleString(undefined, { minimumFractionDigits: min, maximumFractionDigits: max });
};